@tailwind base;
@tailwind components;
@tailwind utilities;

.prose-lg {
  font-size: 1.125rem;
  line-height: 1.7777778;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
}

.prose-invert {
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
}

.ibm-plex-sans-thin {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-sans-extralight {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-sans-light {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-sans-regular {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-sans-medium {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-sans-semibold {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-sans-bold {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.ibm-plex-sans-thin-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.ibm-plex-sans-extralight-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.ibm-plex-sans-light-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.ibm-plex-sans-regular-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.ibm-plex-sans-medium-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.ibm-plex-sans-semibold-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.ibm-plex-sans-bold-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}
